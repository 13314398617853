@import './colors.scss';

.footer-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 8px;

  .footer-app-icons {
    a {
      margin-left: 8px;
    }
  }

  .footer-links {
    text-align: right;

    a {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      text-align: right;
      color: #798391;
      line-height: 17px;
      margin-left: 16px;
    }
  }
}

// TABLET
@media screen and (min-width: 768px) and (max-width: 991px) {
  .footer-container {
    display: none;
  }
}

// // MOBILE
@media screen and (max-width: 767px) {
  .footer-container {
    display: none;
  }
}
