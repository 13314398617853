@import './colors.scss';

// .menu-container {
//   width: 100%;
//   display: flex;
//   position: fixed;
//   top: 0;
//   height: 27px;
//   padding: 24px 18px;
//   align-items: center;
//   justify-content: start;
//   align-content: center;

//   svg {
//     display: inline-flex;
//     margin-right: 18px;
//   }
// }

// .menu-page-container {
//   padding: 24px;
//   overflow: auto;
//   height: 100%;

//   .menu-header {
//     display: flex;
//     width: 100%;
//     padding-bottom: 16px;
//     justify-content: flex-end;

//     svg {
//       fill: $b87;
//       cursor: pointer;
//     }
//   }

//   .menu-content {
//     overflow: auto;

//     font-weight: 600;
//     vertical-align: middle;

//     a {
//       svg {
//         display: inline-block;
//         margin-right: 10px;
//       }
//     }

//     ul {
//       list-style-type: none;

//       li {
//         margin-bottom: 16px;
//       }
//     }
//   }

//   .menu-share .footnote {
//     color: $b38;
//   }
// }

// DESKTOP
// @media screen and (min-width: 992px) {
//   .menu-container {
//     display: none;
//   }
// }

.menu-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  // pointer-events: none;

  // .menu-view__menu.show {
  //   transform: none;
  //   opacity: 1;
  //   visibility: visible;
  // }
}

.menu-view {
  width: 400px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  // visibility: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  pointer-events: auto;
  padding: 12px 16px;

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 20px;
    align-content: flex-end;
    // height: 40px;
  }

  &__content {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    flex-direction: column;

    ul {
      li {
        margin-bottom: 16px;
      }
    }

    .menu-view__share {
      a {
        display: inline-block;
        margin-right: 8px;
      }
      div {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

/* MOBILE-MENU*/
.mobile-menu {
  position: absolute;
  z-index: 200;
  top: 60px;
  left: 0;
  bottom: 0;
  right: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  transform: translateX(-400px);
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
    0px 8px 8px rgba(50, 50, 71, 0.08);

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 16px;

  &__footer {
    display: flex;
    flex-direction: column;

    .locale-switcher {
      margin-bottom: 16px;
    }
  }

  &__list {
    ul > li {
      margin-bottom: 1em;
    }
  }
}

.mobile-menu.show {
  transform: none;
  opacity: 1;
  visibility: visible;
}

.header-mobile {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 100;

  // @media screen and (min-width: 992px) {
  //   display: none;
  // }

  @media screen and (min-width: 768px) {
    display: none;
  }

  &__menu {
    display: flex;
    height: 60px;
    align-items: center;

    svg {
      cursor: pointer;
      margin-left: 16px;
    }
  }

  &__search {
    display: flex;
    padding: 0 16px;
    margin-bottom: 16px;

    .filter-toggle-empty {
      width: 42px;
      height: 42px;

      background: $b38;
      border: 1px solid $b12;
      box-sizing: border-box;
      border-radius: 8px;

      cursor: pointer;

      // /* shadow */
      // box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
      //   0px 8px 8px rgba(50, 50, 71, 0.08);

      svg {
        fill: $white;
        margin: 8px 0 0 7px;
      }
    }

    .filter-toggle {
      width: 42px;
      height: 42px;

      background: $mcolor;
      border: 1px solid $b12;
      box-sizing: border-box;
      border-radius: 8px;

      cursor: pointer;

      /* shadow */
      // box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
      //   0px 8px 8px rgba(50, 50, 71, 0.08);

      svg {
        fill: $white;
        margin: 8px 0 0 7px;
      }
    }
  }

  &__search-form {
    cursor: pointer;
    flex-grow: 1;
    position: relative;
    margin-right: 16px;

    input {
      border: 1px solid $b12;
      box-sizing: border-box;
      border-radius: 8px;
      height: 42px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.01em;
      padding-left: 44px;
      padding-right: 44px;

      outline: none;
      /* light/b38 */

      color: $black;
    }

    button {
      height: 42px;
      width: 42px;

      border: none;
      background: transparent;
      outline: none;

      cursor: pointer;
      color: #f9f0da;

      svg {
        fill: #798391;
        margin-top: 2px;
      }
    }

    button.search {
      position: absolute;
      top: 0;
      left: 0px;
    }

    button.clear {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &_list-search {
    padding: 12px 16px;
    display: flex;
    align-items: center;

    form {
      position: relative;
      flex-grow: 1;

      button {
        height: 42px;
        width: 42px;

        border: none;
        background: transparent;
        outline: none;

        cursor: pointer;
        color: #f9f0da;

        svg {
          fill: #798391;
          margin-top: 2px;
        }
      }

      button.search {
        position: absolute;
        top: 0;
        left: 0;
      }

      button.clear {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      svg {
        fill: $b54;
      }
    }
  }
}
