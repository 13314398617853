@import './colors.scss';

body {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  color: $b87;

  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.01em;
  }

  h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.01em;
  }

  .headline {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }

  .subtitle2 {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  .subtitle {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  .footnote {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
  }

  .caption {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
  }

  .tagline {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
  }
}
