@import './colors.scss';

// DESKTOP
@media screen and (min-width: 992px) {
  .share-container {
    // padding-top: 92px;
  }
}

// TABLET
@media screen and (min-width: 768px) and (max-width: 991px) {
  .share-container {
    padding-top: 92px;
  }
}

// MOBILE
@media screen and (max-width: 767px) {
  .share-container {
    padding-top: 0;
  }
}

.share-container {
  padding: 24px;
  overflow: auto;
  height: 100%;

  .share-header {
    display: flex;
    width: 100%;
    padding-bottom: 16px;
    justify-content: space-between;

    svg {
      fill: $b87;
      cursor: pointer;
    }
  }

  .share-content {
    overflow: auto;

    font-weight: 600;
    vertical-align: middle;

    a {
      color: $b87;
      display: flex;
      line-height: 24px;

      svg {
        display: inline-block;
        fill: $mcolor;
        margin-right: 10px;
      }
    }

    ul {
      list-style-type: none;

      li {
        margin-bottom: 30px;
      }
    }
  }
}
