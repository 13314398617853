@import './colors.scss';

.page-container {
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 767px) {
    padding: 60px 0 0 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.page-container.no-header {
  padding: 16px 0;
}

.page-header {
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    display: none;
  }

  nav {
    line-height: 64px;

    ul {
      display: flex;
      vertical-align: middle;
      list-style: none;

      li {
        align-self: center;
        margin-left: 20px;

        a {
          line-height: 1;
          display: block;
          text-align: center;
          word-wrap: break-word;
        }

        svg {
          display: block;
          fill: $b87;
        }
      }

      li:first-child {
        margin-left: 0;
      }
    }
  }
}

.page-content {
  margin: 0 0 20px 0;
  // width: 100%;
  width: 600px;

  // @media screen and (min-width: 768px) and (max-width: 991px) {
  //   width: 100%;
  // }

  // MOBILE
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .page-style {
    padding: 0 16px;

    h1 {
      margin-bottom: 16px;
    }

    h2 {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 16px;
    }

    ul {
      padding-left: 1em;
      list-style-type: disc;

      li {
        list-style-type: inherit;
        margin-bottom: 16px;
      }
    }

    ol {
      list-style: none;
      counter-reset: li;

      ol {
        padding-left: 1em;
      }

      li {
        padding-bottom: 1em;
      }
    }
  }
}
