@import './colors.scss';

// FILTER
.filter {
  padding-top: 67px;
  height: 100%;
  position: relative;

  // MOBILE
  @media screen and (max-width: 767px) {
    padding-top: 67px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: 27px;
    padding: 16px 16px 16px 16px;

    @media screen and (min-width: 768px) {
      display: none;
    }

    h2 {
      display: inline-block;
    }

    span {
      display: inline-block;
      // flex-direction: column;
      padding: 7px 14px;
      /* light/b12 */
      border: 2px solid $b38;
      background: $b38;
      border-radius: 17px;
      color: $white;
    }

    svg {
      margin-right: 10px;
      margin-bottom: -6px;
      width: 27px;
      height: 27px;
      fill: $black;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    // left: 0;
    // right: 0;
    // width: 100%;
    // display: flex;
    // justify-content: center;
    padding-bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &__list {
    padding: 0 16px 12px 16px;
    height: 100%;
    overflow: auto;

    // MOBILE
    @media screen and (max-width: 767px) {
      padding-bottom: 58px;
    }

    .tags-category {
      margin-bottom: 22px;

      h3 {
        color: $b87;
        mix-blend-mode: normal;
        letter-spacing: 0.01em;
        padding-bottom: 14px;

        .tags-category-icon {
          fill: $black;
          margin-bottom: -7px;
          margin-right: 7px;
        }
      }
    }

    span {
      cursor: pointer;
      display: inline-block;
      // flex-direction: column;
      padding: 7px 14px;
      margin-right: 4px;
      margin-bottom: 4px;

      /* light/b12 */
      border: 2px solid $b12;
      background: $b12;
      border-radius: 17px;
      color: $b54;
    }

    span.empty {
      background: $white;
    }

    span.active {
      background: $mcolor;
      border: 2px solid $mcolor;
      color: $white;
    }
  }
}

// DESKTOP
@media screen and (min-width: 768px) {
  .filter-container {
    .filter-header {
      display: none;
    }
    .filter-bottom {
      display: none;
    }
  }
}

// TABLET
@media screen and (min-width: 768px) and (max-width: 991px) {
  .filter-container {
    padding-top: 76px;

    .tags-list {
      padding-bottom: 64px;
    }
  }
}

// // MOBILE
// @media screen and (max-width: 767px) {
//   .filter-container {
//     padding-top: 76px;

//     .tags-list {
//       padding-bottom: 64px;
//     }
//   }
// }
