@import './colors.scss';
@import './typography.scss';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import './button.scss';
@import './map.scss';
@import './sidebar.scss';
@import './filter.scss';
@import './share.scss';
@import './menu.scss';
@import './list.scss';
@import './footer.scss';
@import './logo.scss';
@import './search.scss';
@import './page.scss';
@import './avatar.scss';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $white;
  height: 100%;
}

input {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
}

li {
  list-style-type: none; /* Убираем маркеры */
}

ul {
  margin-left: 0; /* Отступ слева в браузере IE и Opera */
  padding-left: 0; /* Отступ слева в браузере Firefox, Safari, Chrome */
}

a {
  text-decoration: none;
  color: $b87;
  line-height: 0;
}

#root {
  height: 100%;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}

// main {
//   width: 100%;
//   height: 100vh;
// }

input::-ms-clear {
  display: none;
}
/* Удалить круглые углы */
input[type='search'] {
  -webkit-appearance: none;
}
/* Скрыть кнопку отмены */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
/* Скрыть лупу */
input[type='search']::-webkit-search-results-button {
  -webkit-appearance: none;
}

input {
  border: 1px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 8px;
  height: 42px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  padding-left: 44px;
  padding-right: 44px;

  outline: none;
  /* light/b38 */

  color: #000;
}

// DETAIL
.detail-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.detail-container.not-images {
  padding-top: 66px;
}

.request-close-container {
  right: 0;
  left: 0;
  padding: 10px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: 768px) {
    display: none;
  }

  span {
    background: rgba(255, 255, 255, 0.7);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;

    svg {
      align-self: center;
      fill: $b87;
    }
  }
}

.request-image {
  width: 100%;
  height: 250px;
  overflow: hidden;

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.request-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  // border-top: 1px solid #e4e7eb;

  .request-textblock {
    line-height: 20px;

    p.request-textblock-title {
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      color: #241d35;
      margin-bottom: 8px;
    }

    p.request-textblock-description {
      font-size: 15px;
    }
  }
}

.request-share {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  vertical-align: bottom;

  .button {
    margin: 6px 0;
  }

  a:first-child {
    margin-bottom: 16px;
  }
}

// REQUEST HEADER
.request-header {
  line-height: 20px;
  display: flex;
  margin-bottom: 16px;

  .request-avatar {
    margin-right: 16px;
    width: 42px;
    height: 42px;

    // .request-avatar-wrapper {
    //   user-select: none;
    //   border-radius: 50%;
    //   overflow: hidden;
    //   width: 100%;
    //   height: 100%;

    //   img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //     text-align: center;
    //     text-indent: 10000px;
    //     color: transparent;
    //   }
    // }

    // .request-verified {
    //   position: absolute;

    //   top: 0;
    //   right: 0;

    //   svg {
    //     border-radius: 50%;
    //     box-shadow: 0 0 0 1px $white;
    //   }
    // }
  }

  .request-name {
    font-size: 15px;
    font-weight: 600;
  }

  .request-date {
    font-size: 15px;
    font-weight: normal;
    color: $b54;
  }
}

// REQUEST TEXTBLOCK
.request-textblock {
  line-height: 20px;

  p.request-textblock-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #241d35;
    margin-bottom: 8px;
  }

  p.request-textblock-description {
    font-size: 15px;
  }
}

// request-special-info
.request-special-info {
  margin-top: 16px;
  font-size: 13px;
  line-height: 17px;

  p {
    margin-bottom: 8px;
  }

  svg {
    fill: $b38;
    width: 14px;
    height: 14px;
    margin-bottom: -2px;
    margin-right: 8px;
  }
}

.request-tags {
  color: $b38;
  font-size: 13px;
  line-height: 17px;
  font-family: 'Noto Sans';

  span {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.locale-switcher {
  display: flex;
  align-items: center;

  span {
    color: $b54;
    a {
      color: $b87;
    }
  }

  span::before {
    content: '|';
    margin: 8px;
    color: $b54;
  }

  span:first-child::before {
    content: none;
  }
}

.request-map {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 16px;

  .request-map__container {
    height: 100%;
    flex: 1;
  }

  .request-map__show-button {
    position: absolute;
    bottom: 51px;
  }
}

.detail-page-container {
  .request-image {
    height: 360px;
    overflow: hidden;
    margin-bottom: 16px;

    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .request-content {
    margin-bottom: 16px;
    padding: 0 16px;

    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }

  .request-share {
    display: flex;
    margin-bottom: 16px;
    padding: 0 16px;

    @media screen and (min-width: 768px) {
      width: 100%;

      flex-direction: row;
      justify-content: space-around;
      padding: 0;

      a {
        width: 50%;
        // width: calc(50% - 8px);
      }

      .button {
        margin: 0;
      }
    }
  }

  .request-list {
    padding: 0 16px;

    @media screen and (min-width: 768px) {
      padding: 0;
    }

    h2 {
      margin-bottom: 16px;
    }

    .request-item {
      margin-bottom: 32px;
      padding: 0;
      border-top: none;
    }
  }
}
