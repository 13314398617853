@import './colors.scss';

.sidebar-container {
  .sidebar-view {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    bottom: 0;
    -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
    opacity: 0;
    visibility: hidden;
    background-color: $white;
    box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
      0px 8px 8px rgba(50, 50, 71, 0.08);
    width: 400px;

    @media screen and (max-width: 767px) {
      width: auto;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  .sidebar-view.show {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  .sidebar-toggle-button {
    position: absolute;
    top: 12px;
    left: 416px;

    width: 42px;
    height: 42px;

    background: $white;
    border: 1px solid $b12;
    box-sizing: border-box;
    border-radius: 8px;

    z-index: 1;

    /* shadow */
    box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
      0px 8px 8px rgba(50, 50, 71, 0.08);

    @media screen and (max-width: 767px) {
      display: none;
    }

    svg {
      fill: $b54;
    }

    svg.arrow-right {
      margin: 9px 0 0 10px;
    }

    svg.arrow-left {
      margin: 9px 0 0 8px;
    }
  }
}

//DESKTOP
// @media screen and (min-width: 992px) {
//   .sidebar-view {
//     width: 400px;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     margin: 0 0 0 -400px;
//   }
// }

// TABLET
// @media screen and (min-width: 768px) and (max-width: 991px) {
//   .sidebar-container {
//     .sidebar-view {
//       width: 100%;
//       -webkit-transform: translateX(-100%);
//       transform: translateX(-100%);
//     }

//     .sidebar-toggle-button {
//       display: none;
//     }
//   }
// }

// // // MOBILE
// @media screen and (max-width: 767px) {
//   .sidebar-container {
//     .sidebar-view {
//       width: auto;
//       position: absolute;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       -webkit-transform: translateX(-100%);
//       transform: translateX(-100%);
//     }
//   }
// }

// .sidebar-container {
//   // display: flex;
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   width: 400px;
//   // height: 100%;
//   transition: margin 0.3s;
//   margin: 0 0 0 -400px;
//   z-index: 1000;
//   background-color: $white;
//   box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
//     0px 8px 8px rgba(50, 50, 71, 0.08);
// }

// .sidebar-container.sidebar-open {
//   margin-left: 0;
// }

// .sidebar-toggle-button {
//   position: absolute;
//   top: 24px;
//   left: 416px;

//   width: 42px;
//   height: 42px;

//   background: $white;
//   border: 1px solid $b12;
//   box-sizing: border-box;
//   border-radius: 8px;

//   z-index: 1002;

//   /* shadow */
//   box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
//     0px 8px 8px rgba(50, 50, 71, 0.08);

//   svg {
//     fill: $black;
//   }

//   svg.arrow-right {
//     margin: 9px 0 0 10px;
//   }

//   svg.arrow-left {
//     margin: 9px 0 0 8px;
//   }
// }

// .sidebar-filter-toggle {
//   // position: absolute;
//   // top: 24px;
//   // left: 416px;

//   width: 42px;
//   height: 42px;

//   background: $white;
//   border: 1px solid $b12;
//   box-sizing: border-box;
//   border-radius: 8px;

//   z-index: 1002;

//   /* shadow */
//   box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
//     0px 8px 8px rgba(50, 50, 71, 0.08);

//   svg {
//     fill: $black;
//   }

//   svg.arrow-right {
//     margin: 9px 0 0 10px;
//   }

//   svg.arrow-left {
//     margin: 9px 0 0 8px;
//   }
// }
