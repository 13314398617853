@import './colors.scss';

.search-mobile-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 66px;
  padding: 12px;

  form {
    position: relative;
    max-width: 80%;
    margin-right: 20px;

    input {
      border: 1px solid $b12;
      box-sizing: border-box;
      border-radius: 8px;
      height: 42px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.01em;
      padding-left: 44px;
      padding-right: 44px;

      outline: none;
      /* light/b38 */

      color: #000;
    }

    button {
      height: 42px;
      width: 42px;

      border: none;
      background: transparent;
      outline: none;

      cursor: pointer;
      color: #f9f0da;

      svg {
        fill: #798391;
        margin-top: 2px;
      }
    }

    button.search {
      position: absolute;
      top: 0;
      left: 0px;
    }

    button.clear {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .filter-toggle {
    width: 42px;
    height: 42px;

    background: $mcolor;
    border: 1px solid $b12;
    box-sizing: border-box;
    border-radius: 8px;

    z-index: 1002;

    /* shadow */
    box-shadow: 0px 8px 16px rgba(50, 50, 71, 0.06),
      0px 8px 8px rgba(50, 50, 71, 0.08);

    svg {
      fill: $white;
      margin: 8px 0 0 7px;
    }
  }
}

//SEARCH
.search-container {
  position: absolute;
  top: 12px;
  left: 16px;
  width: 368px;
  z-index: 1001;
  text-align: center;

  @media screen and (max-width: 767px) {
    display: none;
  }

  input {
    border: 1px solid #e4e7eb;
    box-sizing: border-box;
    border-radius: 8px;
    height: 42px;
    width: 100%;
    float: left;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    padding-left: 44px;
    padding-right: 44px;

    outline: none;
    /* light/b38 */

    color: $b87;
  }

  button {
    height: 42px;
    width: 42px;

    border: none;
    background: transparent;
    outline: none;

    cursor: pointer;
    color: #f9f0da;

    svg {
      fill: #798391;
      margin-top: 2px;
    }
  }

  button.search {
    position: absolute;
    top: 0;
    left: 0;
  }

  button.clear {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// DESKTOP
// @media screen and (min-width: 992px) {
//   .search-mobile-container {
//     display: none;
//   }
// }

// // TABLET
// @media screen and (min-width: 768px) and (max-width: 991px) {
//   .search-container {
//     display: none;
//   }
// }

// // MOBILE
// @media screen and (max-width: 767px) {
//   .search-container {
//     display: none;
//   }
// }
