@import './colors.scss';

// LIST
.list-container {
  padding-top: 66px;
  height: 100%;
  overflow: 'auto';
}

.list-message {
  padding: 20px;
}

.request-list {
  width: 100%;

  ul {
    display: block;

    li.request-item {
      display: flex;
      flex-direction: column;
      padding: 20px;
      cursor: pointer;
      border-top: 1px solid $b12;
    }

    li.request-item:hover {
      background-color: $b12;
    }
  }
}

.list-bottom {
  position: fixed;
  padding-bottom: 16px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.list-search-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  display: flex;
  align-items: center;

  form {
    position: relative;
    flex-grow: 1;

    button {
      height: 42px;
      width: 42px;

      border: none;
      background: transparent;
      outline: none;

      cursor: pointer;
      color: #f9f0da;

      svg {
        fill: #798391;
        margin-top: 2px;
      }
    }

    button.search {
      position: absolute;
      top: 0;
      left: 0;
    }

    button.clear {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    svg {
      fill: $b54;
    }
  }
}

.list-show-button {
  position: fixed;
  padding-bottom: 16px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 768px) {
    display: none;
  }
}
// DESKTOP
@media screen and (min-width: 992px) {
  .list-container {
    .list-search-mobile {
      display: none;
    }

    .list-bottom {
      display: none;
    }
  }
}

// TABLET
@media screen and (min-width: 768px) and (max-width: 991px) {
  .list-container {
    // padding-top: 0;
  }
}

// MOBILE
@media screen and (max-width: 767px) {
  .list-container {
    // padding-top: 0;
  }
}
