.logo-container {
  position: absolute;
  display: flex;
  align-items: center;
  top: 12px;
  right: 16px;

  .logo-container__toggle-button {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 42px;
    height: 42px;
    border-radius: 8px;
    margin-left: 16px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);

    svg {
      fill: $b54;
    }
  }
}

// TABLET
@media screen and (min-width: 768px) and (max-width: 991px) {
  .logo-container {
    display: none;
  }
}

// // MOBILE
@media screen and (max-width: 767px) {
  .logo-container {
    display: none;
  }
}
