@import './colors.scss';

.avatar {
  width: 100%;
  height: 100%;
  position: relative;

  &__wrapper {
    position: relative;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 50%;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__approved {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__named {
    background-color: $mcolor;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      color: $white;
    }
  }
}
