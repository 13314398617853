@import './colors.scss';

// BUTTON
.button {
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-decoration: none;
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
}

.button:active,
.button:focus {
  outline: none;
}
.button::-moz-focus-inner {
  border: 0;
}

.b-sm {
  padding: 5px;
}

.b-md {
  padding: 10px;
}

.b-lg {
  padding: 13.5px;
  font-size: 17px;
  line-height: 21px;
  border: 2px solid;
}

.b-tr-green {
  color: $mcolor;
  background: transparent;
  border-color: $mcolor;
}

.b-green {
  background-color: $mcolor;
  color: $white;
  border-color: $mcolor;
}

.b-tr-white {
  color: $white;
  background: transparent;
  border-color: $white;
}

.b-white {
  background-color: $white;
  color: $mcolor;
  border-color: $white;
}
// BUTTON END
