$white: #fff;
$black: #000;

$appbg: #f5f6f7;
$mcolor: #43a047;

$b87: #241d35;
$b54: #798391;
$b38: #969fab;
$b12: #e4e7eb;
